

















import Vue from "vue";
export default Vue.extend({
  name: "categories-mobile",

  data(){
    return{

    }
  },

  props: {
  category: { type: Object, required: true },
  
},

})
