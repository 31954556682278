




















































import Vue from "vue";
import Header from "@/views/Header.vue";
import Breadcrumb from "@/components/desktop/Breadcrumb.vue";
import Carousel from "@/components/desktop/Carousel.vue";
import ItemDescCard from "@/components/desktop/ItemDescCard.vue";
import PriceLocationCard from "@/components/desktop/PriceLocationCard.vue";
import SellerDetails from "@/components/desktop/SellerDetails.vue";
import ItemCardHorizontal from "@/components/desktop/ItemCardHorizontal.vue";
import ItemCardSlider from "@/components/desktop/MapLocation.vue";
import GetDirectionBtn from "@/components/GetDirectionBtn.vue";
import CategoriesCardMobile from "@/components/mobile/CategoriesCardMobile.vue";
import HorizontalScroll from "@/components/mobile/HorizontalScrollCategory.vue";



export default Vue.extend({
  name: "components",
  data() {
    return {
      sliderImages: [
        "https://picsum.photos/1024/480/?image=10",
        "https://picsum.photos/1024/480/?image=12",
        "https://picsum.photos/1024/480/?image=22",
        "https://picsum.photos/1024/480/?image=23"
      ],
      dirPosition: {
        lat: 26.1432891,
        lng: 91.5627972
      }
    };
  },
  components: {
     "header-top": Header,
    breadcrumb: Breadcrumb,
    carousel: Carousel,
    "item-desc-card": ItemDescCard,
    "price-location-card": PriceLocationCard,
    "seller-details": SellerDetails,
    "item-card-horizontal": ItemCardHorizontal,
    GetDirectionBtn,
    "categories-card-mobile": CategoriesCardMobile,
    "horizontal-scroll":HorizontalScroll,
    
  }
});
